import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import Like from '../../images/partner-landing/ic-like.png'
import Cible from '../../images/partner-landing/des-clients-cibles.png'
import Cochon from '../../images/partner-landing/des-affaires-prosperes.png'
import Shake from '../../images/partner-landing/handshake.png'
import Quebec from '../../images/partner-landing/service-travers.png'
import Doigt from '../../images/partner-landing/des-demandes.png'
import People from '../../images/partner-landing/deja-plus.png'

const BenefitsContainer = styled.div`
    img {
        display: block;
        height: 150px;
        margin: 0 auto;
    }
    h3 {
        height: 72px;
        font-weight: 600;
    }
    h3,
    p {
        text-align: center;
    }
    > div:nth-child(1) {
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 40px;
        h2 {
            font-weight: 600;
            text-align: center;
        }
    }
    > div:nth-child(2) {
        max-width: 960px;
        margin: 0 auto;
    }
    @media (max-width: 667px) {
        > div:nth-child(1) {
            img {
                height: 75px;
            }
        }
        > div:nth-child(2) {
            width: 100%;
        }
        h3 {
            height: initial;
            padding-bottom: 1px;
        }
    }
`

const Benefit = ({ img, title, description }) => (
    <Grid item sm={12} md={4}>
        <img src={img} />
        <h3>{title}</h3>
        <p>{description}</p>
    </Grid>
)

const Benefits = ({}) => {
    return (
        <BenefitsContainer>
            <div>
                <img src={Like} alt="" />
                <h2>L’AVANTAGE SOUMISSIONS AVOCAT</h2>
            </div>
            <Grid container spacing={4}>
                <Benefit
                    img={Cible}
                    title="DES CLIENTS CIBLÉS"
                    description="À la recherche de vos qualifications sur votre territoire."
                />
                <Benefit
                    img={Cochon}
                    title="DES AFFAIRES PROSPÈRES"
                    description="Les clients viennent à vous d’eux-mêmes et ont des besoins réels."
                />
                <Benefit
                    img={Shake}
                    title="DES CONTRATS FLEXIBLES"
                    description="Pas d’engagement long-terme. Forfaits renouvelables."
                />
                <Benefit
                    img={Quebec}
                    title="SERVICE À TRAVERS LA PROVINCE"
                    description="Des clients pour vous peu importe où vous exercez."
                />
                <Benefit
                    img={Doigt}
                    title="DES DEMANDES À PROFUSION"
                    description="C’est simple : plus vous en prenez, plus vou travaillez !"
                />
                <Benefit
                    img={People}
                    title="DÉJÀ PLUS DE 20 PARTENAIRES"
                    description="Qui bénéficient de notre service de leads clé en main."
                />
            </Grid>
        </BenefitsContainer>
    )
}

export default Benefits
