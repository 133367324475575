import React from 'react'
import { FormControlLabel, Checkbox } from '@material-ui/core'
export default class CustomCheckbox extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            checked: props.checked,
        }
    }
    handleClick = (evt) => {
        this.setState({
            checked: evt.target.checked,
        })
        this.props.handler(evt)
    }

    render() {
        const { name, label } = this.props
        return (
            <FormControlLabel
                control={<Checkbox />}
                label={label}
                name={name}
                onChange={this.handleClick}
                checked={this.state.checked}
            />
        )
    }
}

export const PureCheckbox = ({ name, checked, label, handler }) => {
    return (
        <FormControlLabel
            control={<Checkbox />}
            label={label}
            name={name}
            onChange={handler}
            checked={checked}
        />
    )
}
