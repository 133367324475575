import React from 'react'
import styled from 'styled-components'
import MissionImg from '../../images/partner-landing/ic-mission.png'

const MissionContainer = styled.div`
    align-items: center;
    background-color: #121233;
    color: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    p {
        max-width: 450px;
        text-align: center;
    }
`

const Mission = () => {
    return (
        <MissionContainer>
            <img src={MissionImg} alt="" />
            <h2>NOTRE MANDAT</h2>
            <p>
                Nous vous procurons rapidement et simplement des demandes
                d’offre de service de gens qui recherchent un avocat compétent
                dans leur région.
            </p>
        </MissionContainer>
    )
}

export default Mission
