import React from 'react'
import styled from 'styled-components'
import NetworkImg from '../../images/partner-landing/ic-reseau.png'
import { Grid } from '@material-ui/core'

const NetworkContainer = styled.div`
    background-color: #1d7bbf;
    color: white;
    padding: 40px;

    > div {
        max-width: 1160px;
        margin: 0 auto;
    }
    > div:nth-child(1) {
        display: flex;
        flex-direction: column;
        max-width: 660px;
        img {
            width: 75px;
        }
        justify-content: center;
        align-items: center;
        p,
        h2 {
            text-align: center;
        }
        h2 {
            font-weight: 600;
        }
    }
    > div:nth-child(2) {
        display: flex;
        justify-content: center;
        span {
            display: block;
            font-size: 30px;
            font-weight: 600;
            text-align: center;
            margin-top: 20px;
        }
        p {
            text-align: center;
        }
    }
    @media (max-width: 667px) {
        > div:nth-child(2) {
            width: 100%;
        }
    }
`

const Stat = ({ num, description }) => {
    return (
        <Grid item xs={12} md={2}>
            <span>{num}</span>
            <p>{description}</p>
        </Grid>
    )
}

const Network = () => {
    return (
        <NetworkContainer>
            <div>
                <img src={NetworkImg} alt="" />
                <h2>UN RÉSEAU DE CONFIANCE</h2>
                <p>
                    Nous nous spécialisons dans la génération de leads de
                    qualité pour les professionnels et les entreprises dans
                    divers domaines, tout en fournissant au public des services
                    de qualité à juste prix.
                </p>
            </div>
            <Grid container spacing={4}>
                <Stat
                    num="600"
                    description="Entreprises et professionnels partenaires"
                />
                <Stat
                    num="200 000+"
                    description="Soumissions traitées via notre réseau"
                />
                <Stat num="20+" description="Régions du Québec desservies" />
                <Stat num="650+" description="Articles de référence publiés" />
                <Stat
                    num="100 000"
                    description="Visiteurs par mois du Québec"
                />
            </Grid>
        </NetworkContainer>
    )
}

export default Network
