import React from 'react'
import styled from 'styled-components'
import { h3 } from '../../constants/theme'
const ConfirmedWrapper = styled.div`
  color: black !important;
  font-size: ${h3};
  font-weight: 600;
  padding: 1rem;
  text-align: center;

  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: 1s opacity ease-in;
  @media (max-width: 992px) {
    padding-top: 0.9rem;
  }
`

export default class Confirmed extends React.Component {
  constructor() {
    super()
    this.state = {
      active: 0,
    }
  }
  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        active: 1,
      })
    }, 32)
  }
  render() {
    const { active } = this.state
    return (
      <ConfirmedWrapper active={active}>
        Merci l'un de nos représentants communiquera avec vous sous peu! Bon
        développement d'affaires!
      </ConfirmedWrapper>
    )
  }
}
