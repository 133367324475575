import React from 'react'
import styled from 'styled-components'
import Layout from '../../components/Layout'

import Woman from '../../images/partner-landing/avocat-girl.png'
import Man from '../../images/partner-landing/avocat-man.png'
import BgTop from '../../images/partner-landing/bgtop-partenaire.png'
import BgBot from '../../images/partner-landing/bgbot-partenaire.jpeg'
import PartnerForm from '../../../../src/components/Preregister'

const Container = styled.div`
    background: ${(props) =>
        `url(${props.top ? BgTop : BgBot}) no-repeat left center`};
    padding: 50px 0px;
    background-size: cover !important;
    color: #fff;
    overflow: hidden;
    .form {
        max-width: 660px;
        margin: 0 auto;
        position: relative;
        > div {
            background-color: #1b9dcc;
            > div {
                padding: 20px;
            }
            input,
            label {
                color: white;
            }
        }
        h1,
        h2,
        p {
            display: block;
            text-align: center;
        }
        h1,
        h2 {
            text-transform: uppercase;
        }
        h2,
        p {
            padding: 20px 20px 10px;
        }
        h1 {
            padding: 40px 20px;
            background-color: #121233;
            font-weight: 600;
        }
        img {
            position: absolute;
        }
        img:nth-child(2) {
            bottom: -100px;
            left: -350px;
        }
        img:nth-child(3) {
            bottom: -100px;
            right: -350px;
        }
        @media (max-width: 768px) {
            img {
                display: none;
            }
            > div {
                margin: 0 20px;
            }
        }
        @media (max-width: 1024px) {
            img:nth-child(2) {
                left: -250px;
            }
            img:nth-child(3) {
                right: -250px;
            }
        }
    }
`

const PartnerFormContainer = ({ top }) => {
    return (
        <Container top={top}>
            <div className="form">
                <PartnerForm
                    shortForm={true}
                    brand="Soumissions-Avocat"
                    submitCallback={() => {
                        alert(
                            'Merci de votre inscription, un représentant communiquera sous peu avec vous.'
                        )
                    }}
                >
                    <h1>Devenez partenaire de soumissions avocat</h1>
                    <h2>Obtenez des clients sérieux qui cherchent un avocat</h2>
                    <p>
                        Complétez simplement le formulaire pour nous faire part
                        de votre intérêt et l’un de nos agents vous contactera
                        dans les plus brefs délais.
                    </p>
                </PartnerForm>
                <img src={Woman} alt="avocate" />
                <img src={Man} alt="avocat" />
            </div>
        </Container>
    )
}

export default PartnerFormContainer
