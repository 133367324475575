import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import Icon from '../Icon'
import { primary } from '../../constants/theme'
import styled from 'styled-components'

const SnackbarContentWrapper = styled(SnackbarContent)`
  && {
    color: white;
    background-color: ${props => (props.error ? 'red' : primary)};
    > div {
      text-align: center;
      width: 100%;
    }
    span {
      font-size: 1.1rem;
    }
  }
`

const CustomSnackBar = props => {
  const { openState, msg, onClose, error } = props
  return (
    <Snackbar
      open={openState}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={5000}
    >
      <SnackbarContentWrapper
        error={error}
        message={
          <span>
            {error === 1 && <Icon name="error" color="#fff" />}
            <span>{msg}</span>
          </span>
        }
      />
    </Snackbar>
  )
}

export default CustomSnackBar
