import React from 'react'
import styled from 'styled-components'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'

import PartnerContainer from '../containers/PartnerLanding'

const PartnerLanding = ({ location }) => {
    return (
        <Layout location="location">
            <PartnerContainer />
        </Layout>
    )
}

export default PartnerLanding

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
