import React, { Fragment } from 'react'
import ProfileForm from '../ProfileForm'
import ShortForm from '../ProfileForm/shortForm'
import Confirmed from './confirmed'
import PadSvg from './padSvg'
import apiUrl from '../../utils/apiUrl'
import SnackBar from '../SnackBar'
import { Link } from 'gatsby'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { h3, h5 } from '../../constants/theme'
import { UserConsumer } from '../../contexts/UserContext'
import { withTranslation, Trans } from 'gatsby-plugin-react-i18next'
const TermsWrapper = styled.div`
    & {
        margin-top: 10px;
        text-align: center;
        * {
            font-size: ${h5};
        }
    }
`

const HeadingWrapper = styled.div`
    text-align: center;
    margin-bottom: 40px;
    span {
        font-size: ${h3};
    }
`

class Preregister extends React.Component {
    constructor() {
        super()
        this.state = {
            confirmed: false,
            error: 0,
            msg: '',
            snackbarOpen: false,
            validationErrors: [],
        }
    }

    setError = () => {
        this.setState({
            confirmed: false,
            error: 1,
            snackbarOpen: true,
            msg: this.props.t(`cantRegister`),
        })
    }

    closeSnackbar = () => {
        this.setState({
            snackbarOpen: false,
            msg: '',
        })
    }

    getReferrer = () => {
        const cookieArr = document.cookie.split(';')
        for (let i = 0; i < cookieArr.length; i++) {
            const [name, value] = cookieArr[i].split('=')
            if (name.trim() === 'referrer') {
                return value.trim()
            }
        }
        return ''
    }

    submit = (postData) => {
        const { session, shortForm } = this.props
        const { language = 'fr' } = this.props.i18n
        postData.preferredLang = language
        postData.referrer = this.getReferrer()
        const url = `//${apiUrl}/users/lawyers/register`
        fetch(url, {
            method: 'POST',
            redirect: 'follow',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
        })
            .then((res) => {
                if (res.ok) {
                    res.json().then((json) => {
                        if (this.props.submitCallback) {
                            this.props.submitCallback()
                        } else {
                            const token = session.saveSession(json.token)
                            if (language === 'en') {
                                navigate(
                                    `/en/professionnel/${token.user}/edit?preregister=1`
                                )
                            } else {
                                navigate(
                                    `/professionnel/${token.user}/edit?preregister=1`
                                )
                            }
                        }
                    })

                    this.setState({
                        snackbarOpen: false,
                        confirmed: true,
                        error: 0,
                    })
                    window.scrollTo(
                        0,
                        window.pageYOffset +
                            this.top.getBoundingClientRect().top
                    )
                } else if (res.status === 400) {
                    this.setError()
                } else {
                    this.setError()
                }
            })
            .catch((err) => {
                console.log(err)
                //this.setError()
            })
    }

    render() {
        const { t } = this.props

        return (
            <div id="preregisterDiv" ref={(el) => (this.top = el)}>
                {!this.props.children && !this.props.formOnly && (
                    <HeadingWrapper>
                        <PadSvg />
                        <h2 style={{ fontWeight: '600' }}>
                            <Trans i18nKey="register">Inscris-toi</Trans>
                        </h2>
                        <span>
                            <Trans i18nKey="oneOfOurReps">
                                et l'un de nos représentants communiquera avec
                                toi rapidement
                            </Trans>
                        </span>
                    </HeadingWrapper>
                )}
                {this.props.children}

                {!this.state.confirmed && (
                    <Fragment>
                        {(this.props.shortForm && (
                            <ShortForm
                                submit={this.submit}
                                errors={this.state.validationErrors}
                                noPassword={this.props.noPassword}
                                defaultProvince={this.props.defaultProvince}
                                noProvince={this.props.noProvince}
                            />
                        )) || (
                            <ProfileForm
                                submit={this.submit}
                                errors={this.state.validationErrors}
                            />
                        )}
                        <TermsWrapper>
                            <Link to={t('link.terms')}>
                                <Trans i18nKey="terms">
                                    Termes et Conditions
                                </Trans>
                            </Link>
                            {!this.props.formOnly ? (
                                <>
                                    <br />
                                    <span>
                                        &copy; {this.props.brand || 'JuriGo.ca'}{' '}
                                        2017-2021
                                    </span>
                                </>
                            ) : null}
                        </TermsWrapper>
                    </Fragment>
                )}
                {this.state.confirmed && <Confirmed />}
                <SnackBar
                    openState={this.state.snackbarOpen}
                    msg={this.state.msg}
                    error={this.state.error}
                    onClose={this.closeSnackbar}
                />
            </div>
        )
    }
}

export default withTranslation()((props) => (
    <UserConsumer {...props}>
        <Preregister {...props} />
    </UserConsumer>
))
