import React from 'react'
import PartnerForm from './form'
import Mission from './mission'
import Steps from './steps'
import Benefits from './benefits'
import Network from './network'

export default () => (
    <div>
        <PartnerForm top={true} />
        <Mission />
        <Steps />
        <Benefits />
        <Network />
        <PartnerForm top={false} />
    </div>
)
