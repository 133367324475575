import React from 'react'
import styled from 'styled-components'
import { h3 } from '../../constants/theme'
const IconContainer = styled.div`
  color: ${props => (props.color ? props.color : 'grey')};
  display: ${props => (props.inline ? 'inline-flex' : 'flex')};
  align-items: flex-start;
  justify-content: center;
  flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
  padding: 0.3rem;

  & > i {
    height: ${props => (props.fontSize ? props.fontSize : h3)};
  }
  & > i,
  & > span {
    font-size: ${props => (props.fontSize ? props.fontSize : h3)};
    display: inline-block;
  }
  & > span {
    margin: 0 5px 0;
    min-height: ${props => (props.fontSize ? props.fontSize : h3)};
    line-height: 1.1;
  }
`

export default ({ text, style, reverse, name, fontSize, inline, color }) => (
  <IconContainer
    reverse
    style={style}
    fontSize={fontSize}
    inline={inline}
    color={color}
  >
    {text && <span>{text}</span>}
    <i className="material-icons">{name}</i>
  </IconContainer>
)
