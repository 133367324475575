import React from 'react'

export default class PadSvg extends React.Component {
  render = () => {
    return (
      <svg width="1.588cm" height="1.623cm">
        <path
          fillRule="evenodd"
          fill="#4c2975"
          d="M34.781,24.389 L34.781,3.705 L3.727,3.705 L3.727,42.295 L18.011,42.295 C19.036,42.295 19.875,43.129 19.875,44.148 C19.875,45.166 19.036,46.000 18.011,46.000 L1.863,46.000 C0.838,46.000 -0.000,45.166 -0.000,44.148 L-0.000,1.852 C-0.000,0.833 0.839,-0.000 1.863,-0.000 L36.644,-0.000 C37.669,-0.000 38.507,0.833 38.507,1.852 L38.507,24.389 C38.507,25.408 37.669,26.242 36.644,26.242 C35.619,26.242 34.781,25.408 34.781,24.389 ZM44.377,28.897 C43.600,28.217 42.420,28.279 41.737,29.051 L31.520,40.350 L26.396,35.442 C25.651,34.731 24.471,34.762 23.757,35.503 C23.042,36.244 23.073,37.417 23.819,38.128 L30.340,44.364 C30.682,44.703 31.147,44.889 31.644,44.889 C31.675,44.889 31.675,44.889 31.706,44.889 C32.203,44.858 32.700,44.642 33.011,44.271 L44.501,31.552 C45.215,30.749 45.153,29.576 44.377,28.897 ZM27.949,10.805 L11.180,10.805 C10.155,10.805 9.316,11.639 9.316,12.658 C9.316,13.677 10.155,14.510 11.180,14.510 L27.949,14.510 C28.974,14.510 29.812,13.677 29.812,12.658 C29.812,11.639 28.974,10.805 27.949,10.805 ZM29.812,22.846 C29.812,21.827 28.974,20.993 27.949,20.993 L11.180,20.993 C10.155,20.993 9.316,21.827 9.316,22.846 C9.316,23.864 10.155,24.698 11.180,24.698 L27.949,24.698 C28.974,24.698 29.812,23.864 29.812,22.846 ZM11.180,30.872 C10.155,30.872 9.316,31.706 9.316,32.725 C9.316,33.744 10.155,34.577 11.180,34.577 L16.148,34.577 C17.173,34.577 18.011,33.744 18.011,32.725 C18.011,31.706 17.173,30.872 16.148,30.872 L11.180,30.872 Z"
        />
      </svg>
    )
  }
}
