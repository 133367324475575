import React, { Component } from 'react'
import TextField from '../TextField'

import CheckboxGroup from '../../components/CheckboxGroup'
import Checkbox from '../../components/Checkbox'
import { Grid, MenuItem } from '@material-ui/core'

import lawyerTypeLabels, {
    lawyerTypesON,
    lawyerTypesFlatMap,
} from '../../constants/lawyerTypes'
import languageLabels from '../../constants/languages'
import regionLabels, { regionsON } from '../../constants/regions'
import { validateForm, validateField } from '../../utils/reactFormValidation'
import apiUrl from '../../utils/apiUrl'
import MaterialButton from '@material-ui/core/Button'
import RadioDollars from '../Dollars'
import SnackBar from '../../components/SnackBar'
import { Paper, Tabs, Tab } from '@material-ui/core'
import styled from 'styled-components'
import debounce from 'debounce'
import getProfileFields from './fieldsDef'
import { withTranslation, Trans } from 'gatsby-plugin-react-i18next'

const CustomPaper = styled(Paper)`
    margin: 30px 0;
    padding: 40px;
    width: 100%;
`

const HeadingGrid = styled(Grid)`
    font-size: 1.5rem !important;
    font-weight: 600;
    margin-top: 10px !important;
    span {
        font-size: 1.5rem;
    }
`
const Button = styled(MaterialButton)`
    && {
        display: flex;
        margin: 0 auto;
    }
`

class ProfileForm extends Component {
    constructor(props) {
        super(props)
        const fields = getProfileFields()

        if (props.fieldsValue) {
            Object.keys(props.fieldsValue).forEach((key) => {
                if (fields[key]) {
                    if (key === 'legalAidCerts') {
                        fields[key].value = this.setParentLegalAidCerts(
                            props.fieldsValue[key]
                        )
                    } else {
                        fields[key].value = props.fieldsValue[key]
                    }
                }
            })
        }

        this.state = {
            loading: true,
            toggleRender: false,
            snackbarOpen: false,
            error: 0,
            msg: '',
            uploaderOpen: false,
            photoRef: '',
            fields: fields,
            firstName: '',
            provinceTab: 0,
        }
    }
    changeProvinceTab = (_, provinceTab) => {
        this.setState({
            ...this.state,
            provinceTab: provinceTab,
            toggleRender: !this.state.toggleRender,
        })
    }
    _setElementValue = (name, value, valid) => {
        this.setState((state) => {
            let v = value
            if (value === '') {
                v = null
            }
            return {
                fields: {
                    ...state.fields,
                    [name]: {
                        ...state.fields[name],
                        value: v,
                        valid,
                    },
                },
            }
        })
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState.toggleRender !== this.state.toggleRender
    }
    setRadio = (name, value) => {
        this.setState({
            fields: {
                ...this.state.fields,
                minRate: {
                    ...this.state.fields.minRate,
                    value: value,
                    valid: true,
                },
            },
            toggleRender: !this.state.toggleRender,
        })
    }
    setCheckboxes = (name, values) => {
        const valid = values.length > 0
        this.setState((state) => {
            return {
                fields: {
                    ...state.fields,
                    [name]: {
                        ...state.fields[name],
                        valid,
                        value: values,
                    },
                },
            }
        })
    }

    setChildrenLegalAidCerts = (legalAidCerts, lawyerTypes) => {
        let newLegalAidCerts = []
        const legalAidCertsParents = {
            'on-family': legalAidCerts.indexOf('on-family') > -1 ? true : false,
            'on-criminal':
                legalAidCerts.indexOf('on-criminal') > -1 ? true : false,
        }
        return lawyerTypes.filter((lt) => {
            const parent = lawyerTypesFlatMap[lt]?.parent
            if (
                legalAidCertsParents[parent] ||
                legalAidCerts.indexOf(lt) >= 0
            ) {
                return true
            }
            return false
        })
    }

    // set parent level legalAid certs if one of the children is present in the original list
    setParentLegalAidCerts = (legalAidCerts) => {
        const duplicates = {}
        return legalAidCerts
            .map((lac) => {
                const parent = lawyerTypesFlatMap[lac].parent
                if (lawyerTypesFlatMap[parent].certRequired) {
                    if (!duplicates[parent]) {
                        duplicates[parent] = true
                        return parent
                    } else {
                        return null
                    }
                }
                return lac
            })
            .filter((lac) => {
                if (lac === null) {
                    return false
                }
                return true
            })
    }

    submit = (evt) => {
        evt.preventDefault()
        let { valid, fields } = validateForm(this)

        this.setState((state) => {
            return {
                fields: fields,
                snackbarOpen: !valid,
                toggleRender: !valid ? !state.toggleRender : state.toggleRender,
                error: 1,
                errorMsg: this.props.t(`Le formulaire contient des erreurs.`),
            }
        })
        if (!valid) {
            window.scrollTo(
                0,
                window.pageYOffset + this.top.getBoundingClientRect().top - 100
            )
            return
        }
        const bodyObject = {}
        Object.keys(fields).forEach((key) => {
            if (
                key === 'yearJoinedBar' ||
                key === 'minRate' ||
                key === 'leadsRate'
            ) {
                bodyObject[key] = parseInt(fields[key].value, 10)
            } else if (key === 'legalAidCerts') {
                bodyObject[key] = this.setChildrenLegalAidCerts(
                    fields.legalAidCerts.value,
                    fields.lawyerTypes.value
                )
            } else {
                bodyObject[key] = fields[key].value
            }
        })
        this.props.submit(bodyObject)
    }

    closeSnackbar = () => {
        this.setState((state) => ({
            snackbarOpen: false,
            msg: '',
            toggleRender: !state.toggleRender,
        }))
    }

    render() {
        const {
            firstName,
            lastName,
            email,
            postalCode,
            employementType,
            lawyerTypes,
            languages,
            yearJoinedBar,
            minRate,
            address,
            city,
            firm,
            juridicalHelp,
            phoneNumber,
            contactPreference,
            regions,
            preferredLang,
            barProvince,
            legalAidCerts,
            leadsRate,
            admin,
            mediator,
        } = this.state.fields
        const { t } = this.props
        return (
            <div
                style={{ maxWidth: '90%', margin: '0 auto' }}
                ref={(el) => (this.top = el)}
            >
                <form onSubmit={this.submit}>
                    <Grid container spacing={2}>
                        <HeadingGrid xs={12} item>
                            <span>
                                <Trans>Informations Personnelles</Trans>
                            </span>
                        </HeadingGrid>

                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="firstName"
                                name="firstName"
                                className="textInput"
                                label="Prénom"
                                autoComplete="given-name"
                                onChange={this._setElementValue}
                                helperText={'Ce champ est requis'}
                                {...firstName}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                helperText={'Ce champ est requis'}
                                id="lastName"
                                name="lastName"
                                className="textInput"
                                label={t('Nom')}
                                autoComplete="family-name"
                                onChange={this._setElementValue}
                                {...lastName}
                            />
                        </Grid>
                        <Grid xs={12} item sm={6}>
                            <TextField
                                select
                                fullWidth
                                helperText={'Ce champ est requis.'}
                                id="preferredLang"
                                name="preferredLang"
                                className="textInput"
                                label="Langue préférée"
                                autoComplete="preferredLang"
                                onChange={this._setElementValue}
                                {...preferredLang}
                            >
                                <MenuItem
                                    value="0"
                                    disabled
                                    style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                                >
                                    <Trans i18nKey="preferredLang">
                                        Langue préférée
                                    </Trans>
                                </MenuItem>
                                <MenuItem key="fr" value="fr">
                                    <Trans i18nKey="FR">Français</Trans>
                                </MenuItem>
                                <MenuItem key="en" value="en">
                                    <Trans i18nKey="EN">Anglais</Trans>
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid xs={12} item>
                            <label
                                style={{
                                    fontSize: '16px',
                                    marginBottom: '20px',
                                    color: 'rgba(0, 0, 0, 0.54)',
                                }}
                            >
                                <Trans>Langues parlées</Trans>
                            </label>
                            <CheckboxGroup
                                initialChecked={languages.value}
                                updateState={this.setCheckboxes}
                                checkboxes={languageLabels}
                                name="languages"
                                helperText="Veuillez sélectionner au moins une langue"
                                valid={languages.valid}
                                key={`langs ${languages.valid}`}
                            />
                        </Grid>
                        <HeadingGrid xs={12} item>
                            <span>
                                <Trans i18nKey="contactInfo">
                                    Coordonnées &mdash; bureau
                                </Trans>
                            </span>
                        </HeadingGrid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                {...firm}
                                helperText={'Ce champ est requis'}
                                id="firm"
                                name="firm"
                                className="textInput"
                                label="Nom de votre firme"
                                onChange={this._setElementValue}
                            />
                        </Grid>
                        <Grid xs={12} item sm={6}>
                            <TextField
                                fullWidth
                                helperText={
                                    'Ce champ doit être un courriel valide'
                                }
                                id="email"
                                name="email"
                                className="textInput"
                                type="email"
                                label="Courriel"
                                autoComplete="email"
                                onChange={this._setElementValue}
                                {...email}
                            />
                        </Grid>
                        <Grid xs={12} item sm={6}>
                            <TextField
                                fullWidth
                                helperText={'Ce champ est requis'}
                                id="phoneNumber"
                                name="phoneNumber"
                                className="textInput"
                                type="phoneNumber"
                                label="Numéro de téléphone"
                                autoComplete="phoneNumber"
                                onChange={this._setElementValue}
                                {...phoneNumber}
                            />
                        </Grid>
                        <Grid xs={12} item sm={6}>
                            <TextField
                                select
                                fullWidth
                                helperText={'Ce champ est requis.'}
                                id="contactPreference"
                                name="contactPreference"
                                className="textInput"
                                type="contactPreference"
                                label="Meilleur moment pour me joindre"
                                autoComplete="contactPreference"
                                onChange={this._setElementValue}
                                {...contactPreference}
                            >
                                <MenuItem
                                    value="0"
                                    disabled
                                    style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                                >
                                    <Trans i18nKey="bestTime">
                                        Meilleur moment pour me joindre
                                    </Trans>
                                </MenuItem>
                                <MenuItem key="morning" value="morning">
                                    <Trans i18nKey="morning">
                                        Avant-midi (9h-12h)
                                    </Trans>
                                </MenuItem>
                                <MenuItem key="afternoon" value="afternoon">
                                    <Trans i18nKey="afternoon">
                                        Après-midi (13h-17h)
                                    </Trans>
                                </MenuItem>
                                <MenuItem key="evening" value="evening">
                                    <Trans i18nKey="evening">
                                        Soir (17h et plus)
                                    </Trans>
                                </MenuItem>
                                <MenuItem
                                    key="alreadyContacted"
                                    value="alreadyContacted"
                                >
                                    <Trans i18nKey="alreadyTalked">
                                        J’ai déjà parlé avec un représentant
                                    </Trans>
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid xs={12} item>
                            <TextField
                                fullWidth
                                helperText={'Ce champ est requis'}
                                id="address"
                                name="address"
                                className="textInput"
                                label="Adresse"
                                autoComplete="address"
                                onChange={this._setElementValue}
                                {...address}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                helperText={'Ce champ est requis'}
                                id="city"
                                name="city"
                                className="textInput"
                                label="Ville"
                                autoComplete="city"
                                onChange={this._setElementValue}
                                {...city}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                helperText={
                                    'Ce champ doit être un code postal valide'
                                }
                                id="postalCode"
                                name="postalCode"
                                className="textInput"
                                label="Code postal"
                                autoComplete="postal-code"
                                onChange={this._setElementValue}
                                {...postalCode}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                select
                                fullWidth
                                {...employementType}
                                helperText={'Ce champ est requis'}
                                name="employementType"
                                id="employementTypeSelect"
                                onChange={this._setElementValue}
                            >
                                <MenuItem
                                    value="0"
                                    disabled
                                    style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                                >
                                    <Trans>Salarié(e) ou?</Trans>
                                </MenuItem>
                                <MenuItem key={'employee'} value={'employee'}>
                                    <Trans>Salarié(e)</Trans>
                                </MenuItem>
                                <MenuItem key={'partner'} value={'partner'}>
                                    <Trans>Associé(e)</Trans>
                                </MenuItem>
                                <MenuItem key={'freelance'} value={'freelance'}>
                                    <Trans>À mon compte</Trans>
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField
                                fullWidth
                                {...yearJoinedBar}
                                helperText={'Ce champ est requis.'}
                                id="yearJoinedBar"
                                name="yearJoinedBar"
                                className="textInput"
                                label="Année d’inscription à l’ordre"
                                onChange={this._setElementValue}
                            />
                        </Grid>

                        <HeadingGrid xs={12} item>
                            <span>
                                <Trans>Informations Professionelles</Trans>
                            </span>
                        </HeadingGrid>
                        <Grid xs={12} item sm={8}>
                            <TextField
                                select
                                fullWidth
                                helperText={'Ce champ est requis.'}
                                id="leadsRate"
                                name="leadsRate"
                                className="textInput"
                                type="leadsRate"
                                label="Combien de leads voulez-vous recevoir par semaine?"
                                autoComplete="leadsRate"
                                onChange={this._setElementValue}
                                {...leadsRate}
                            >
                                <MenuItem
                                    value="0"
                                    disabled
                                    style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                                >
                                    <Trans i18nKey="numLeadsPerWeek">
                                        Nombre de leads par semaine
                                    </Trans>
                                </MenuItem>
                                <MenuItem key="1to5" value="1">
                                    <Trans i18nKey="1to5">1 à 5</Trans>
                                </MenuItem>
                                <MenuItem key="6to10" value="2">
                                    <Trans i18nKey="6to10">6 à 10</Trans>
                                </MenuItem>
                                <MenuItem key="10to20" value="3">
                                    <Trans i18nKey="10to20">10 à 20</Trans>
                                </MenuItem>
                                <MenuItem key="asManyAsPossible" value="4">
                                    <Trans i18nKey="asManyAsPossible">
                                        Autant que possible
                                    </Trans>
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item sm={8} xs={12}>
                            <Grid container>
                                <Grid item sm={8} xs={12}>
                                    <RadioDollars
                                        value={minRate.value.toString()}
                                        onChange={this.setRadio}
                                    />
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <small>
                                        <strong>$</strong>{' '}
                                        <Trans>Moins de 150$/h</Trans>
                                    </small>
                                    <br />
                                    <small>
                                        <strong>$$</strong>{' '}
                                        <Trans>150$-250$/h</Trans>
                                    </small>
                                    <br />
                                    <small>
                                        <strong>$$$</strong>{' '}
                                        <Trans>Plus de 250$/h</Trans>
                                    </small>
                                </Grid>
                            </Grid>
                        </Grid>

                        {barProvince.value === 'QC' ? (
                            <>
                                <Grid
                                    xs={12}
                                    item
                                    style={{ marginBottom: '20px' }}
                                >
                                    <label
                                        style={{
                                            fontSize: '16px',
                                            marginBottom: '20px',
                                            color: 'rgba(0, 0, 0, 0.54)',
                                        }}
                                    >
                                        <Trans>
                                            Régions dans lesquelles vous voulez
                                            des mandats au Québec
                                        </Trans>
                                    </label>
                                    <CheckboxGroup
                                        initialChecked={regions.value}
                                        updateState={this.setCheckboxes}
                                        checkboxes={regionLabels}
                                        name="regions"
                                        helperText="Veuillez sélectionner au moins une région"
                                        valid={regions.valid}
                                        key={`regions ${regions.valid}`}
                                    />
                                </Grid>
                                <Grid xs={12} item>
                                    <label
                                        style={{
                                            fontSize: '16px',
                                            marginBottom: '20px',
                                            color: 'rgba(0, 0, 0, 0.54)',
                                        }}
                                    >
                                        <Trans>
                                            Vos champs de pratique au Québec
                                        </Trans>
                                    </label>
                                    <CheckboxGroup
                                        initialChecked={lawyerTypes.value}
                                        updateState={this.setCheckboxes}
                                        checkboxes={lawyerTypeLabels}
                                        name="lawyerTypes"
                                        helperText="Veuillez sélectionner au moins un champ de pratique"
                                        valid={lawyerTypes.valid}
                                        key={`lawyerTypes ${lawyerTypes.valid}`}
                                    />
                                </Grid>
                            </>
                        ) : null}
                        {barProvince.value === 'ON' ? (
                            <>
                                <Grid
                                    xs={12}
                                    item
                                    style={{ marginBottom: '20px' }}
                                >
                                    <label
                                        style={{
                                            fontSize: '16px',
                                            marginBottom: '20px',
                                            color: 'rgba(0, 0, 0, 0.54)',
                                        }}
                                    >
                                        <Trans>
                                            Régions dans lesquelles vous voulez
                                            des mandats en Ontario
                                        </Trans>
                                    </label>
                                    <CheckboxGroup
                                        initialChecked={regions.value}
                                        updateState={this.setCheckboxes}
                                        checkboxes={regionsON}
                                        name="regions"
                                        helperText="Veuillez sélectionner au moins une région"
                                        valid={regions.valid}
                                        key={`regions ${regions.valid}`}
                                    />
                                </Grid>
                                <Grid xs={12} item>
                                    <label
                                        style={{
                                            fontSize: '16px',
                                            marginBottom: '20px',
                                            color: 'rgba(0, 0, 0, 0.54)',
                                        }}
                                    >
                                        <Trans>
                                            Vos champs de pratique en Ontario
                                        </Trans>
                                    </label>
                                    <CheckboxGroup
                                        initialChecked={lawyerTypes.value}
                                        updateState={this.setCheckboxes}
                                        checkboxes={lawyerTypesON}
                                        name="lawyerTypes"
                                        helperText="Veuillez sélectionner au moins un champ de pratique"
                                        valid={lawyerTypes.valid}
                                        key={`lawyerTypes ${lawyerTypes.valid}`}
                                        secondaryName="legalAidCerts"
                                        secondaryInitialChecked={
                                            legalAidCerts.value
                                        }
                                    />
                                </Grid>
                            </>
                        ) : null}
                        <Grid xs={12} item>
                            <Checkbox
                                name="mediator"
                                checked={mediator.value}
                                handler={(evt) => {
                                    this._setElementValue(
                                        'mediator',
                                        evt.target.checked,
                                        true
                                    )
                                }}
                                label={
                                    <Trans>{this.props.t('iAmMediator')}</Trans>
                                }
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <Checkbox
                                handler={(evt) => {
                                    this._setElementValue(
                                        'juridicalHelp',
                                        evt.target.checked,
                                        true
                                    )
                                }}
                                name={'juridicalHelp'}
                                id={'juridicalHelp'}
                                label={this.props.t('acceptLegalAid')}
                                checked={juridicalHelp.value}
                                fullWidth
                            />
                        </Grid>

                        <Grid xs={12} item>
                            <Button variant="outlined" type="submit">
                                Soumettre
                            </Button>
                        </Grid>
                    </Grid>
                </form>

                <SnackBar
                    openState={this.state.snackbarOpen}
                    msg={this.state.errorMsg}
                    error={this.state.error}
                    onClose={this.closeSnackbar}
                />
            </div>
        )
    }
}

export default withTranslation()(ProfileForm)
