import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import Step1 from '../../images/partner-landing/sa-etape1.jpeg'
import Step2 from '../../images/partner-landing/sa-etape2.jpeg'
import Step3 from '../../images/partner-landing/sa-etape3.jpeg'

const StepsContainer = styled(Grid)`
    padding: 40px;
    max-width: 1060px;
    margin: 0 auto;
    h2 {
        text-align: center;
        width: 100%;
    }
    img {
        width: 100%;
    }
    span {
        display: block;
        padding: 10px 10px 5px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 21px;
    }
    span:nth-of-type(1) {
        color: #006fbf;
    }
    p,
    span {
        text-align: center;
    }
`

const Step = ({ img, title, stepNum, description }) => {
    return (
        <Grid item sm={12} md={4}>
            <img src={img} alt="" />
            <span>Étape {stepNum}</span>
            <span>{title}</span>
            <p>{description}</p>
        </Grid>
    )
}

const Steps = () => {
    return (
        <StepsContainer container spacing={2}>
            <h2>
                Devenez Partenaire en <strong>Un Rien de Temps !</strong>
            </h2>
            <Step
                img={Step1}
                stepNum={1}
                title="FAITES VOTRE DEMANDE EN 1 MIN"
                description="Un agent vous contactera rapidement pour vous donner de l’information supplémentaire."
            />
            <Step
                img={Step2}
                stepNum={2}
                title="IDENTIFIEZ VOTRE CLIENTÈLE CIBLE"
                description="L’agent vous aidera à définir votre territoire, à identifier votre clientèle cible et à choisir votre forfait."
            />
            <Step
                img={Step3}
                stepNum={3}
                title="OBTENEZ DE NOUVEAUX CLIENTS"
                description="Des gens sérieux qui font eux-mêmes une demande pour trouver un avocat compétent dans leur secteur."
            />
        </StepsContainer>
    )
}

export default Steps
