import React, { Component } from 'react'
import TextField from '../TextField'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { validateForm, validateField } from '../../utils/reactFormValidation'
import MaterialButton from '@material-ui/core/Button'
import SnackBar from '../../components/SnackBar'
import Paper from '@material-ui/core/Paper'
import styled from 'styled-components'
import getProfileFields from './fieldsDef'
import { withTranslation, Trans } from 'gatsby-plugin-react-i18next'
const CustomPaper = styled(Paper)`
    margin: 30px 0;
    padding: 40px;
    width: 100%;
`

const HeadingGrid = styled(Grid)`
    font-size: 1.5rem !important;
    font-weight: 600;
    margin-top: 10px !important;
    span {
        font-size: 1.5rem;
    }
`
const Button = styled(MaterialButton)`
    && {
        display: flex;
        margin: 0 auto;
        span {
            font-size: 21px;
        }
    }
`

class ShortForm extends Component {
    constructor(props) {
        super(props)
        const fields = getProfileFields([
            'firstName',
            'lastName',
            'email',
            'password',
            'phoneNumber',
            'barProvince',
        ])
        fields.password = {
            value: props.noPassword
                ? Math.random().toString(36).substr(2) +
                  Math.random().toString(36).substr(2)
                : '',
            valid: true,
            regex: new RegExp('.{8,}', 'i'),
        }
        fields.barProvince = {
            ...fields.barProvince,
            value: props.defaultProvince ? props.defaultProvince : '',
        }
        if (props.fieldsValue) {
            Object.keys(props.fieldsValue).forEach((key) => {
                if (fields[key]) {
                    fields[key].value = props.fieldsValue[key]
                }
            })
        }

        this.state = {
            loading: true,
            toggleRender: false,
            snackbarOpen: false,
            error: 0,
            msg: '',
            uploaderOpen: false,
            photoRef: '',
            fields: fields,
            firstName: '',
        }
    }

    _setElementValue = (name, value, valid) => {
        this.setState((state) => {
            let v = value
            if (value === '') {
                v = null
            }
            return {
                fields: {
                    ...state.fields,
                    [name]: {
                        ...state.fields[name],
                        value: v,
                        valid,
                    },
                },
            }
        })
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState.toggleRender !== this.state.toggleRender
    }

    submit = (evt) => {
        evt.preventDefault()
        let { valid, fields } = validateForm(this)

        this.setState((state) => {
            return {
                fields: fields,
                snackbarOpen: !valid,
                toggleRender: !valid ? !state.toggleRender : state.toggleRender,
                error: 1,
                errorMsg: `Le formulaire contient des erreurs.`,
            }
        })
        if (!valid) {
            window.scrollTo(
                0,
                window.pageYOffset + this.top.getBoundingClientRect().top - 100
            )
            return
        }
        const bodyObject = {}
        Object.keys(fields).forEach((key) => {
            if (
                key === 'yearJoinedBar' ||
                key === 'minRate' ||
                key === 'maxRate'
            ) {
                bodyObject[key] = parseInt(fields[key].value, 10)
                return
            }

            bodyObject[key] = fields[key].value
        })
        this.props.submit(bodyObject)
    }

    closeSnackbar = () => {
        this.setState((state) => ({
            snackbarOpen: false,
            msg: '',
            toggleRender: !state.toggleRender,
        }))
    }

    render() {
        const {
            firstName,
            lastName,
            email,
            password,
            phoneNumber,
            barProvince,
        } = this.state.fields
        const { t } = this.props
        return (
            <div
                style={{ maxWidth: '90%', margin: '0 auto' }}
                ref={(el) => (this.top = el)}
            >
                <form onSubmit={this.submit}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                name="firstName"
                                className="textInput"
                                label={t('profile.firstName')}
                                autoComplete="given-name"
                                onChange={this._setElementValue}
                                helperText={t('requiredField')}
                                {...firstName}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                helperText={t('requiredField')}
                                name="lastName"
                                className="textInput"
                                label={t('profile.lastName')}
                                autoComplete="family-name"
                                onChange={this._setElementValue}
                                {...lastName}
                            />
                        </Grid>

                        {this.props.noProvince ? null : (
                            <Grid xs={12} item sm={6}>
                                <TextField
                                    select
                                    fullWidth
                                    helperText={t('requiredField')}
                                    name="barProvince"
                                    className="textInput"
                                    type="barProvince"
                                    label={t('profile.barProvince')}
                                    autoComplete="barProvince"
                                    onChange={this._setElementValue}
                                    {...barProvince}
                                >
                                    <MenuItem
                                        value="0"
                                        disabled
                                        style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                                    >
                                        {t('profile.barProvince')}
                                    </MenuItem>
                                    <MenuItem key="QC" value="QC">
                                        Québec
                                    </MenuItem>
                                    <MenuItem key="ON" value="ON">
                                        Ontario
                                    </MenuItem>
                                </TextField>
                            </Grid>
                        )}

                        <Grid xs={12} item sm={6}>
                            <TextField
                                fullWidth
                                helperText={t('requiredField')}
                                name="phoneNumber"
                                className="textInput"
                                type="phoneNumber"
                                label={t('profile.phoneNumber')}
                                autoComplete="phoneNumber"
                                onChange={this._setElementValue}
                                {...phoneNumber}
                            />
                        </Grid>

                        <Grid
                            xs={12}
                            item
                            sm={
                                this.props.noPassword && !this.props.noProvince
                                    ? 12
                                    : 6
                            }
                        >
                            <TextField
                                fullWidth
                                helperText={t('validEmail')}
                                name="email"
                                className="textInput"
                                type="email"
                                label={t('profile.email')}
                                autoComplete="email"
                                onChange={this._setElementValue}
                                {...email}
                            />
                        </Grid>
                        {this.props.noPassword ? null : (
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label={t('password')}
                                    type="password"
                                    name="password"
                                    helperText={t('passwordMin8')}
                                    onChange={this._setElementValue}
                                    {...password}
                                />
                            </Grid>
                        )}
                        <Grid xs={12} item>
                            <Button
                                variant="contained"
                                color="secondary"
                                type="submit"
                                style={{ fontSize: '16px', fontWeight: 600 }}
                            >
                                <Trans>Obtenir des références de clients</Trans>
                            </Button>
                        </Grid>
                    </Grid>
                </form>

                <SnackBar
                    openState={this.state.snackbarOpen}
                    msg={this.state.errorMsg}
                    error={this.state.error}
                    onClose={this.closeSnackbar}
                />
            </div>
        )
    }
}

export default withTranslation()(ShortForm)
