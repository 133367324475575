const languages = [
  {
    name: 'fr',
    label: 'Francais'
  },
  {
    name: 'en',
    label: 'Anglais'
  },
  {
    name: 'es',
    label: 'Espagnol'
  },
  {
    name: 'it',
    label: 'Italien'
  },
  {
    name: 'el',
    label: 'Grec'
  },
  {
    name: 'zh',
    label: 'Chinois'
  }
]

export default languages

export const languagesMappings = (function() {
  const mappings = {}
  languages.forEach(lang => (mappings[lang.name] = lang.label))
  return mappings
})()
