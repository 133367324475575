import React, { Component } from 'react'
import { navigate } from 'gatsby'
import session from '../../services/session'

const UserContext = React.createContext()

class UserProvider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isAdmin: session.checkAdmin(),
            isReportAdmin: session.checkReportAdmin(),
            ...session.getSession(),
            saveSession: this.saveSession,
            deleteSession: this.deleteSession,
            checkSessionAlive: this.checkSessionAlive,
        }
    }

    saveSession = (tokenString) => {
        session.saveSession(tokenString)
        const sessionObject = session.getSession()
        this.setState({
            isAdmin: session.checkAdmin(),
            ...sessionObject,
        })
        return sessionObject
    }

    deleteSession = (manualLogout) => {
        session.deleteSession()
        this.setState({
            isAdmin: false,
            ...session.getSession(),
        })
        if (manualLogout) {
            navigate('/')
        } else {
            navigate(`/login?${window.location.pathname}`)
        }
    }

    checkSessionAlive = () => {
        if (!session.checkSessionAlive()) {
            this.deleteSession()
            return false
        }
        return true
    }

    render() {
        return (
            <UserContext.Provider value={this.state}>
                {this.props.children}
            </UserContext.Provider>
        )
    }
}

const UserConsumer = ({ children, ...props }) => {
    return (
        <UserContext.Consumer>
            {(session) =>
                React.cloneElement(children, { session: session, ...props })
            }
        </UserContext.Consumer>
    )
}

export default UserContext

export { UserProvider, UserConsumer }
