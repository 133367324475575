const getFields = (requestedFields) => {
    const returnedFields = {}
    const defs = {
        firstName: {
            value: '',
            valid: true,
        },
        lastName: {
            value: '',
            valid: true,
        },
        email: {
            value: '',
            valid: true,
            regex: new RegExp('^(.+)@(.+){2,}.(.+){2,}$', 'i'),
        },

        description: {
            value: '',
            valid: true,
            optional: true,
        },
        firm: {
            value: '',
            valid: true,
        },
        education: {
            value: '',
            valid: true,
            optional: true,
        },
        yearJoinedBar: {
            value: '',
            valid: true,
            regex: new RegExp('^19[4-9][0-9]$|^20[0-1][0-9]$|^202[0-2]$', 'i'),
        },
        leadsRate: {
            value: '0',
            valid: true,
        },
        minRate: {
            value: '',
            valid: true,
            regex: new RegExp('^[0-3]$|^$', 'i'),
            optional: true,
        },
        address: {
            value: '',
            valid: true,
        },
        city: {
            value: '',
            valid: true,
        },
        postalCode: {
            value: '',
            valid: true,
            regex: new RegExp(
                '^[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]$',
                'i'
            ),
        },
        lawyerTypes: {
            value: [],
            valid: true,
        },
        legalAidCerts: {
            value: [],
            valid: true,
            optional: true,
        },
        languages: {
            value: [],
            valid: true,
        },
        otherLawyerType: {
            value: '',
            valid: true,
            optional: true,
        },
        regions: {
            value: [],
            valid: true,
            optional: true,
        },
        lawyerOrNotary: {
            value: 'lawyer',
            valid: true,
        },
        employementType: {
            value: '0',
            valid: true,
        },
        publications: {
            value: '',
            valid: true,
            optional: true,
        },
        implications: {
            value: '',
            valid: true,
            optional: true,
        },
        juridicalHelp: {
            value: false,
            valid: true,
            optional: true,
        },
        phoneNumber: {
            value: '',
            valid: true,
            optional: false,
        },
        contactPreference: {
            value: '',
            valid: true,
            optional: false,
        },
        barProvince: {
            value: '',
            valid: true,
            optional: false,
        },
        preferredLang: {
            value: '',
            valid: true,
            optional: false,
        },
        mediator: {
            value: false,
            valid: true,
            optional: true,
        },
    }

    if (!requestedFields) {
        return defs
    }

    requestedFields.forEach((key) => {
        returnedFields[key] = defs[key]
    })

    return returnedFields
}

export default getFields
