import React from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import styled from 'styled-components'
import { Trans } from 'gatsby-plugin-react-i18next'
const StyledFormControl = styled(FormControl)`
    width: 100%;
    > div {
        margin: 0 auto;
        justify-content: center;
    }
`
const StyledFormControlLabel = styled(FormControlLabel)`
    margin: 5px !important;
`
class RadioDollars extends React.Component {
    handleChange = (event) => {
        this.props.onChange('rate', event.target.value)
    }

    render() {
        return (
            <StyledFormControl component="fieldset">
                <FormLabel component="legend">
                    <Trans>Tarifs de vos honoraires</Trans>
                </FormLabel>
                <RadioGroup
                    aria-label="position"
                    name="position"
                    value={this.props.value}
                    onChange={this.handleChange}
                    row
                >
                    <StyledFormControlLabel
                        value="1"
                        control={<Radio color="primary" />}
                        label="$"
                        labelPlacement="top"
                    />
                    <StyledFormControlLabel
                        value="2"
                        control={<Radio color="primary" />}
                        label="$$"
                        labelPlacement="top"
                    />
                    <StyledFormControlLabel
                        value="3"
                        control={<Radio color="primary" />}
                        label="$$$"
                        labelPlacement="top"
                    />
                </RadioGroup>
            </StyledFormControl>
        )
    }
}

export const DollarSigns = ({ rate }) => {
    const dollarSigns = new Array(rate)
    for (let i = 0; i < 3 && i < rate; i++) {
        dollarSigns.push(<strong key={i}>$</strong>)
    }
    return (
        <div style={{ textAlign: 'center', color: 'grey' }}>{dollarSigns}</div>
    )
}

export default RadioDollars
